@import "_variables";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import '~quill/dist/quill.snow.css';

html,
body {
  height: 100%;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.modal-open {
  overflow: hidden;
}

textarea {
  resize: none;
}
